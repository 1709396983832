import { config, Environment } from 'config';

const { production, development } = {
  production: config.ENV === Environment.Production,
  development: config.ENV === Environment.Development,
};

export const features = {
  hotjar: !development && production,
  sentry: !development && production,
};
